import styled from "styled-components";
import { colors } from "../../../../components/library/theme";

function getOpacity({ isCompleted, showUnfinishedWarning }) {
  if (isCompleted) return 0.4;
  if (showUnfinishedWarning) return 0.6;
  return 1;
};

export const Wrapper = styled.button`
  height: 64px;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: 1px solid ${colors.interface.grey100};
  border-bottom: ${({ isLastElement }) =>
    isLastElement
      ? "1px solid " + colors.interface.grey100
      : "1px solid transparent"};
  border-radius: ${({ isLastElement }) =>
    isLastElement ? "4px 4px" : "4px 4px 0 0"};
  margin-bottom: ${({ isLastElement, showUnfinishedWarning }) => (isLastElement && !showUnfinishedWarning ? "24px" : "0")};
  margin-top: ${({ isFirstElement }) => (isFirstElement ? "24px" : "0")};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;

    button {
      cursor: not-allowed;
    }
  }

  > div:first-of-type {
    display: flex;
    align-items: center;
    width: 100%;

    > h3 {
      font-weight: 600;
      max-width: 70%;
      text-align: left;
      color: ${colors.medtrack.grey500};
    }

    span {
      margin-left: auto;
      margin-right: 32px;
      color: ${colors.medtrack.grey400};
    }

    > h3,
    span {
      margin-bottom: 0;
      font-size: 12px;
      text-decoration: ${({ isCompleted }) =>
    isCompleted ? "line-through" : "none"};
      opacity: ${({ isCompleted, showUnfinishedWarning }) =>
    getOpacity({ isCompleted, showUnfinishedWarning })};
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: baseline;

      h3 {
        max-width: unset;
      }

      span {
        margin: 8px 0 0;
        line-height: 0;
      }
    }
  }

  @media screen and (max-width: 420px) {
    height: auto;
  }

  &:hover {
    background-color: ${colors.medtrack.grey000};
  }
`;
