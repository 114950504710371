import React, { useEffect, useState } from "react";
import { Wrapper } from "./styles";
import { TodoButton } from "./TodoButton";
import { createMockWithLoading } from "../../../../processes/mockProccess";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export function MockTodoItem({
  mock,
  mockTemplate,
  quantityQuestions,
  isLastElement,
  isFirstElement,
  isCompleted,
  isAnyMockBeingCreated,
  setIsAnyMockBeingCreated,
  showUnfinishedWarning,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreatingMock, setIsCreatingMock] = useState(false);
  const mockTitle = mockTemplate?.name ?? mock?.name;
  const isDisabled =
    isCreatingMock ||
    isAnyMockBeingCreated ||
    (showUnfinishedWarning && !isCompleted);

  function handleMockItemClick({ mock, mockTemplate }) {
    if (!mock) {
      return createMockWithLoading(
        { mock_template_id: mockTemplate.id, isMedtrackOrigin: true },
        setIsCreatingMock,
        dispatch
      );
    }

    const baseUrl = window.location.origin;
    const url = new URL(`/mocks/${mock.id}`, baseUrl);
    url.searchParams.set("isMedtrackOrigin", "true");
    let todoItemLink = url.pathname + url.search;

    const isFromOtherHost = window.location.hostname !== url.hostname;
    if (isFromOtherHost) todoItemLink = toDoItemDetails?.link;

    return history.push(todoItemLink);
  }

  useEffect(() => {
    setIsAnyMockBeingCreated(isCreatingMock);
  }, [isCreatingMock]);

  return (
    <Wrapper
      isFirstElement={isFirstElement}
      isLastElement={isLastElement}
      isCompleted={isCompleted}
      showUnfinishedWarning={showUnfinishedWarning}
      onClick={() => handleMockItemClick({ mock, mockTemplate })}
      disabled={isDisabled}
    >
      <div>
        <h3>{mockTitle}</h3>
        <span>{quantityQuestions} questões</span>
      </div>
      <TodoButton
        isCompleted={isCompleted}
        showUnfinishedWarning={showUnfinishedWarning}
        isLoading={isCreatingMock}
      />
    </Wrapper>
  );
}
